export const ITEMS_PER_PAGE = 100;
export const DEBOUNCE_TIMEOUT = 500;
export const DATE_FORMAT = {
  YYYY_MM: "YYYY-MM",
  YYYY_MM_DD: "YYYY-MM-DD",
};
export const RECURRING_BY_CARD = "Recurring by Card";
export const MONTHLY_BY_CARD = "Monthly by Card";
export const CASH = "Cash";
export const CHEQUE = "Cheque";
export const PER_CAMPAIGN_COST = 5;
export const PER_CONTACT_COST = 0.15;
export const WARRANTY_OPTIONS = [
  {text: "Select Warranty", value: null},
  {text: "AS IS", value: "AS IS"},
  {text: "5 days", value: "5 days"},
  {text: "15 days", value: "15 days"},
  {text: "30 days", value: "30 days"},
  {text: "60 days", value: "60 days"},
  {text: "90 days", value: "90 days"},
];
export const ADMIN_TEAM_USERS = [200,259];
export const ATTENTION_POST_IMAGE = {
  id: "24459181-3885-421e-9310-7e8e712b2065",
  tag: "img1",
  url100: process.env.VUE_APP_MEDIA_UPLOAD_SERVER+"public/redline/24459181-3885-421e-9310-7e8e712b2065/original.gif"
};
